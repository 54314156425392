import React, {useContext} from 'react';
import Tree from 'react-d3-tree';
import CHARACTERS from '../../assets/en_kjv_characters.json'
import {Card, CardContent, Typography} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";
const containerStyles = {
   width: '100%',
   height: 500,
   color: '#fff !important',
   fill: '#fff !important',
   '& path': {
      stroke: '#fff !important',
   },
};
const nodeSvgShape = {
   shape: 'circle',
   shapeProps: {
      r: 8,
      fill: '#FFFFFF',
      stroke: '#FFFFFF',
      strokeWidth: 1,
   },
};

const linkSvgShape = {
   strokeWidth: 1,
   stroke: '#FFFFFF',
};
const BibleCharacterNetworkGraph = () => {
   const {
      state: { matchSM}
   } = useContext(SearchContext);
   
   const data = {
      name: "Adam",
      children: mapNodesToTree("Adam", []),
   };
   
   function mapNodesToTree(parentNodeId, mappedNodes) {
      if (mappedNodes.includes(parentNodeId)) {
         return null;
      }
      
      const children = CHARACTERS.links
         .filter((link) => link.source === parentNodeId)
         .map((link) => {
            const childNode = CHARACTERS.nodes.find((node) => node.id === link.target);
            return {
               name: childNode.id,
               children: mapNodesToTree(childNode.id, [...mappedNodes, parentNodeId]),
            };
         });
      
      return children.length > 0 ? children : null;
   }
   
   
   
   const straightPathFunc = (linkDatum, orientation) => {
      const { source, target } = linkDatum;
      return orientation === 'horizontal'
         ? `M${source.y},${source.x}L${target.y},${target.x}`
         : `M${source.x},${source.y}L${target.x},${target.y}`;
   };
   
   return (
      <Card style={{marginTop:20, height: matchSM ? 400 : 400}}>
         <CardContent
            style={containerStyles}>
            <Typography variant="h5">
               People
            </Typography>
         <Tree
            style={containerStyles}
            data={data}
            orientation="vertical"
            collapsible={true}
            transitionDuration={0}
            zoom={1}
            shouldCollapseNeighborNodes={false}
            
            nodeSvgShape={nodeSvgShape}
            linkSvgShape={linkSvgShape}

            rootNodeClassName="node__root"
            branchNodeClassName="node__branch"
            leafNodeClassName="node__leaf"
            orientation={'vertical'}
            siblings={1}
            pathFunc={straightPathFunc}

         />
         </CardContent>
      </Card>
   );
};
export default BibleCharacterNetworkGraph;
