import React, {useState, useEffect, useContext} from 'react';
import Chart from 'react-apexcharts';
import {filterVersesByKeyword} from "../../lib/constants/helpers";
import {Card, CardContent, Grid, TextField, Typography} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";

const BibleVerseCountChartNoCard = () => { /* works */
   const {
      state: { query, debounceQuery, matchSM},
      actions: { setQuery}
   } = useContext(SearchContext);
   const keyword = debounceQuery != '' ? debounceQuery : 'Salvation'
   const [chartData, setChartData] = useState([]);
   
   useEffect(() => {
      const filteredVerses = filterVersesByKeyword(keyword);
      const bookCounts = {};
      filteredVerses.forEach(verse => {
         if (!bookCounts[verse.book]) {
            bookCounts[verse.book] = 0;
         }
         bookCounts[verse.book] += 1;
      });
      
      const chartData = [];
      Object.entries(bookCounts).forEach(([book, count]) => {
         chartData.push({book, count});
      });
      
      setChartData(chartData);
   }, [keyword]);
   
   const options = {
      chart: {
         type: 'bar',
      },
      xaxis: {
         categories: chartData.map(d => d.book)
      },
      series: [{
         name: 'Verse Count',
         data: chartData.map(d => d.count)
      }],
      plotOptions: {
         bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
         },
      },
      dataLabels: {
         enabled: false
      },
      stroke: {
         show: true,
         width: 1,
         colors: ['transparent']
      },
      colors: ['#fff'],
   
   };
   return (
         <div id="chart">
            {Boolean(chartData) && <Chart options={options} series={[{data: chartData.map(d => d.count)}]} type="bar" height={350}/>}
         </div>
   );
};

export default BibleVerseCountChartNoCard;
