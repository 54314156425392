import React, {useContext} from "react";
import {SearchContext} from "../../lib/contexts";
import {Box, Card, CardContent, ListItem, Typography} from "@material-ui/core";
import TIMELINE from '../../assets/en_kjv_timeline_events.json';
const BibleEventList = () => {
   const {
      state: { matchSM},
      actions: { setQuery},
   } = useContext(SearchContext);
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Event Highlights
            </Typography>
            <Box style={{height: matchSM ? 180 : 180, overflowY:'auto', paddingBottom:10}}>
               { Boolean(TIMELINE) && TIMELINE.timeline.map((event, index)=><ListItem key={index} onClick={()=>setQuery(event.event.split(' ')[0])}>{event.event}</ListItem>) }
            </Box>
         </CardContent>
      </Card>
   );
};
export default BibleEventList
