import React, {useCallback, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {Card, CardContent, Divider, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import BioBox from './components/bioBox';
import SearchFormBox from './components/SearchFormBox';
//import TrendingBox from "./components/TrendingBox";
import TitleBox from "./components/TitleBox";
import {SearchContextProvider, ThemeContext} from "./lib/contexts";
import IndexTicker from "./components/IndexTicker";
import DarkModeToggle from "./components/DarkModeToggle";
import { QueryClient, QueryClientProvider } from 'react-query';
import { LightTheme, DarkTheme } from './lib/themes';
import RandomQuote from "./components/RandomQuote";
import ShareBox from "./components/ShareBox";
import SponsoredBox from "./components/SponsoredBox";
import RenderBook from "./components/RenderBook";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SearchDrawer from "./components/SearchDrawer";

const useStyles = makeStyles({
   root: {
      padding:'2.5%',
      width: '100%'
   },
   smallCard: {
      minWidth: 200
   },
   bigCard: {
      margin:'auto auto auto',
      width:'100%'
   }
});

const App = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   //const matchMDD = useMediaQuery(theme.breakpoints.only('md'));
   //const matchMD = useMediaQuery(theme.breakpoints.up('md'));
   //const matchLG = useMediaQuery(theme.breakpoints.only('lg'));
   //const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
   return (
       <Grid
          className={classes.root +" mobile"}
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={matchSM ? 1 : 4}
       >
          <Grid item xs={!matchSM ? 3 : 12}>
               <TitleBox/>
               {!matchSM && <BioBox/>}
               {!matchSM && <SponsoredBox/>}
             {/*
               {!matchSM && <ShareBox/>}
               <TrendingBox/>
             <CopySettingsBox/>
             */}
          </Grid>
          <Grid item xs={!matchSM ? 9 : 12}>
       <Card className={classes.bigCard}>
          <CardContent>
          <Grid
             justifyContent="space-between"
             container
             item
             xs={12}>
             <Grid item xs={8}>
                <IndexTicker/></Grid>
             <Grid item xs={4}>
                <DarkModeToggle/></Grid>
          </Grid>
          <Divider style={{marginTop:5,marginBottom:20}}/>
          <SearchFormBox/>
          {matchSM && <BioBox/>}
          {matchSM && <SponsoredBox/>}
          </CardContent>
          </Card>
             <RandomQuote/>
          </Grid>
          </Grid>
  );
}
const ThemeContainer = () => {
   const [darkMode, setDarkMode] = useState(true || window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
   const toggleTheme = useCallback(() => {
      setDarkMode(!darkMode);
   },[darkMode]);
   const chosenTheme = useMemo(()=>darkMode ? DarkTheme() : LightTheme(),[darkMode]);
   return (
      <ThemeContext.Provider value={{darkMode,toggleTheme}}>
      <ThemeProvider theme={chosenTheme}>
         <BrowserRouter>
         <SearchContextProvider>
            <Routes>
               <Route path="/:bookAbbrev/*" element={<RenderBook />} />
               <Route path="*" element={<RenderBook />} />
            </Routes>
            <SearchDrawer/>
         </SearchContextProvider>
         </BrowserRouter>
      </ThemeProvider>
      </ThemeContext.Provider>
   );
};

const AppContainer = () => {
   const queryClient = new QueryClient();
   return (
      <QueryClientProvider client={queryClient}>
               <ThemeContainer />
      </QueryClientProvider>
   );
};

export default AppContainer;
