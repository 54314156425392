import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getBibleGPT = async (book,chapter) => {
  if(!book || !chapter){
    return true
  }
  const apiURL = API_HOST + '/bible/gpt?book='+book+'&chapter='+chapter;
  const { token, userID } = ''//useCurrentUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-biblegpt-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
