import React, {useContext, useMemo} from 'react';
import {
   Card,
   CardContent,
   Divider,
   Drawer, Grid,
   makeStyles,
   Typography,
   List,
   ListItem,
   ListItemText, CircularProgress
} from '@material-ui/core';
import {
   Skeleton
} from '@material-ui/lab';

import {SearchContext} from "../../lib/contexts";
import {getNameFromAbbreviation, prettyNumber} from "../../lib/constants/helpers";
import TOP_WORDS from "../../assets/en_kjv_top_words.json";
import BOOK from "../../assets/en_kjv_bible.json";
import BibleVerseCountChartNoCard from "../BookCharts/BibleVerseCountChartNoCard";

const useStyles = makeStyles({
   mobile:{
      width:'100% !important',
      minWidth:'100% !important'
   }
});
const SearchDrawer = () => {
   const classes = useStyles();
   const {
      state: {  bibleGPTWordCommentaryLoading, bibleGPTWordCommentaryData, matchSM, searchOpen, query},
      actions: { toggleSearch},
   } = useContext(SearchContext);
   const { Hebrew_Etymology, Greek_Etymology, Commentary } = bibleGPTWordCommentaryData || {};
   const totalUsageCount = useMemo(() => TOP_WORDS.top_words.filter((item) => item.word.toLowerCase() === query.toLowerCase())[0]?.count || 0, [TOP_WORDS,query]);
   const totalUsageList = useMemo(() => {
      const usageList = [];
      BOOK.forEach((book) => {
         book.chapters.forEach((chapter, chapterIndex) => {
            chapter.forEach((verse, verseIndex) => {
               const cleanVerse = verse.replace(/[.,;:'"?!()]/g, '').toLowerCase(); // remove punctuation and convert to lowercase
               if (cleanVerse.includes(query.toLowerCase())) {
                  usageList.push({
                     book: getNameFromAbbreviation(book.abbrev),
                     chapter: chapterIndex + 1,
                     verse: verseIndex + 1,
                     text: verse
                  });
               }
            });
         });
      });
      return usageList;
   }, [query]);
   
   return(
      <Drawer variant={"temporary"} anchor="bottom" open={searchOpen} onClose={toggleSearch}>
         <Card>
            <CardContent style={{maxHeight: matchSM ? 800 : 620, minHeight: matchSM ? 400 : 320, overflowY:'auto', paddingBottom:10}}>
               <Typography variant={matchSM ? "h4" : "h3"} component="h2" gutterBottom>
                  Searching: "{query}"
               </Typography>
               <Divider style={{marginBottom: 20, marginTop: 20}}/>
               <Grid container item xs={12} spacing={2}>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Total Usage Count: {prettyNumber(totalUsageCount)}
                     </Typography>
                     <BibleVerseCountChartNoCard/>
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Found in Verses:
                     </Typography>
                     <div>
                        <List style={{overflowY:'auto',maxHeight: matchSM ? 500 : 480, minHeight: matchSM ? 300 : 250,paddingBottom:10}}>
                           {totalUsageList.map((usage, index) => (
                              <ListItem key={index}>
                                 <ListItemText
                                    primary={
                                       <a href={`../${usage.book}/${usage.chapter}`}>
                                          {`${usage.book} ${usage.chapter}:${usage.verse}`}
                                       </a>
                                    }
                                    secondary={usage.text}
                                 />
                              </ListItem>
                           ))}
                        </List>
                     </div>
                  </Grid>
                  <Grid item xs={matchSM ? 12 : 4}>
                     <Typography gutterBottom variant={'h5'}>
                        Hebrew Etymology
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Boolean(Hebrew_Etymology) && Hebrew_Etymology.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                     <Typography gutterBottom variant={'h5'}>
                        Greek Etymology
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Boolean(Greek_Etymology) && Greek_Etymology.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                     <Typography gutterBottom variant={'h5'}>
                        Commentary
                     </Typography>
                     <div>
                        {bibleGPTWordCommentaryLoading ? (
                           <><CircularProgress style={{display:'block',margin:'20px auto auto'}}/>
                           </>
                        ) : (
                           <>
                              {Boolean(Commentary) && Commentary.map((item, index) => (
                                 <ListItem key={index}>
                                    {item}
                                 </ListItem>
                              ))}
                           </>
                        )}
                     </div>
                  </Grid>
               </Grid>
            </CardContent>
         </Card>
      </Drawer>
)
};

export default SearchDrawer;
