import React, {useState, useEffect, useContext} from 'react';
import Chart from 'react-apexcharts';
import {filterVersesByKeyword} from "../../lib/constants/helpers";
import {Card, CardContent, Grid, TextField, Typography} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";

const BibleVerseCountChart = ({embedded}) => { /* works */
   const {
      state: { query, debounceQuery, matchSM},
      actions: { setQuery}
   } = useContext(SearchContext);
   const keyword = debounceQuery != '' ? debounceQuery : 'Salvation'
   const [chartData, setChartData] = useState([]);
   
   useEffect(() => {
      const filteredVerses = filterVersesByKeyword(keyword);
      const bookCounts = {};
      filteredVerses.forEach(verse => {
         if (!bookCounts[verse.book]) {
            bookCounts[verse.book] = 0;
         }
         bookCounts[verse.book] += 1;
      });
      
      const chartData = [];
      Object.entries(bookCounts).forEach(([book, count]) => {
         chartData.push({book, count});
      });
      
      setChartData(chartData);
   }, [keyword]);
   
   const options = {
      chart: {
         type: 'bar',
      },
      xaxis: {
         categories: chartData.map(d => d.book)
      },
      series: [{
         name: 'Verse Count',
         data: chartData.map(d => d.count)
      }],
      plotOptions: {
         bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
         },
      },
      dataLabels: {
         enabled: false
      },
      stroke: {
         show: true,
         width: 1,
         colors: ['transparent']
      },
      colors: ['#fff'],
   
   };
   return (
      <Card style={{marginTop:20, height: matchSM ? 300 : 400}}>
         <CardContent>
            {!embedded && <Grid container item xs={12} justifyContent={'space-between'} style={{marginBottom: 20}}>
               <Grid item xs={4}>
                  <Typography variant="h5">
                     Keyword Across Books
                  </Typography>
               </Grid>
               <Grid item xs={4} style={{textAlign: 'center'}}>
                  <Typography variant="h5">{`"${keyword}" Usage`}</Typography>
               </Grid>
               <Grid item xs={4}>
                  <TextField
                     placeholder={'Salvation'}
                     style={{float: 'right'}}
                     focused={true}
                     variant={'outlined'}
                     color={'secondary'}
                     type="text"
                     value={query}
                     onChange={e => setQuery(e.target.value)}
                  />
               </Grid>
            </Grid>}
         <div id="chart">
            {Boolean(chartData) && <Chart options={options} series={[{data: chartData.map(d => d.count)}]} type="bar" height={350}/>}
         </div>
         </CardContent>
      </Card>
   );
};

export default BibleVerseCountChart;
