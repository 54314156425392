import { useQuery } from 'react-query';
import {getBibleGPTWordCommentary} from "../api/getBibleGPTWordCommentary";

const useBibleGPTWordCommentary = (term) => {
  const result = useQuery('biblegpt_word_commentary_'+term, () => getBibleGPTWordCommentary(term), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useBibleGPTWordCommentary;
