import { useQuery } from 'react-query';
import { getBibleGPTCommentary } from '../api/getBibleGPTCommentary';

const useBibleGPTCommentary = (book,chapter) => {
  const result = useQuery('biblegpt_commentary_'+book+'_'+chapter, () => getBibleGPTCommentary(book,chapter), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useBibleGPTCommentary;
