import { lighten } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { opacity } from './utils';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const common = {
  transparent: 'transparent',
  black: '#0b0b0c',
  white: '#ffffff',
  red: '#ff0000',
  increase: '#BEF2D7',
  decrease: '#F9CDBA',
  border: 'rgba(0,0,0,0.28)',
  grey: {
    50: '#FAFAFC',
    100: '#F5F5F7',
    200: '#EEEEF2',
    300: '#DEDEE2',
    400: '#BDBDC2',
    500: '#9E9EA8',
    600: '#757575',
    700: '#757575',
    800: '#424247',
    900: '#212126',
  },
};
const palette = (primaryColor, secondaryColor) => {
  return {
    common: common,
    secondary: {
      main: primaryColor || '#FD6F5C', //'#355c7d', BE2A4F
      dark: '#FD6F5C',
      light: '#4779a6',
      text: '#EEEEF2',
      contrastText: '#EEEEF2',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#FD6F5C',
      bodyText: '#EEEEF2',
      paperText: '#EEEEF2',
    },
    primary: {
      main: secondaryColor || '#FD6F5C', //'#ea9c7d', // #ea9c87
      dark: '#FD6F5C',
      light: '#9E9EA8',
      text: '#EEEEF2',
      contrastText: '#EEEEF2',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#FD6F5C',
      bodyText: '#EEEEF2',
      paperText: '#EEEEF2',
    },
    branding: {
      peach: '#fd6f5c',
      orange: '#fd975c',
      pink: '#fd5c87',
    },
    backgrounds: {
      paper: alpha('#333', 0.9),
      paperOpaque: alpha('#333', 1),
      loginPaper: alpha('#171717', 0.7),
      default: '#fafafa',
      header: '#000',
      footer: '#111',
      background: '#111',
      login: alpha('#000', 0.9), // || '#1A1A1A', // 243f54
    },
    admin: {
      main: '#1C6262',
      dark: '#164543',
      light: '#C4DCCC',
      bodyText: '#5B7B79',
      contrastText: '#C6DFCE',
      background: '#c4d8d7',
      backgroundHover: '#1D6362',
      border: '#164543',
      tab: '#B4CCCB',
      activeTab: '#DFE9E9',
      trendChart: '#eff4f4',
      starter: '#EFF6F2',
      premium: '#E5F1E8',
      enterprise: '#D9EADD',
      recommended: '#e2a787',
    },
    error: {
      main: '#DA4045',
      dark: '#A74145',
      light: '#EBB1B3',
      contrastText: '#ffffff',
      background: lighten('#DA4045', 0.92),
      backgroundHover: lighten('#DA4045', 0.5),
      border: '#A74145',
    },
    warning: {
      main: '#f8ab3a',
      dark: '#c38147',
      light: '#ffc960',
      contrastText: '#ffffff',
      background: lighten('#f8ab3a', 0.92),
      backgroundHover: lighten('#f8ab3a', 0.5),
      border: '#c38147',
    },
    success: {
      main: '#45ae57',
      dark: '#3d954c',
      light: '#60ba6f',
      contrastText: '#ffffff',
      background: lighten('#68b76b', 0.92),
      backgroundHover: lighten('#68b76b', 0.5),
      border: '#3d954c',
    },
    info: {
      main: '#536dfe',
      dark: '#25448f',
      light: '#9fa8da',
      contrastText: '#ffffff',
      background: lighten('#536dfe', 0.92),
      backgroundHover: lighten('#536dfe', 0.5),
      border: '#25448f',
    },
  };
};

const theme = (primaryColor, secondaryColor) => {
  const finalPalette = palette(primaryColor, secondaryColor);

  return createTheme({
    themeName: 'Light',
    palette: finalPalette,
    globalClasses: {
      screen: {
        width: '96%',
        margin: '10px auto auto',
      },
      adminTableHead: {
        borderRadius: 5,
        color: finalPalette.primary.main,
        backgroundColor: finalPalette.primary.activeTab,
        '& *': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
      adminTableRow: {
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.primary.trendChart,
        '& *': {
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
      adminTableRowWhite: {
        borderRadius: 5,
        color: finalPalette.primary.bodyText,
        backgroundColor: finalPalette.common.white,
        '& *': {
          color: finalPalette.primary.bodyText,
        },
        '& .MuiTableSortLabel-active': {
          color: finalPalette.primary.dark,
        },
      },
    },
    typography: {
      useNextVariants: true,
      htmlFontSize: 16,
      fontFamily: '"SharpBook19", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightMedium: 400,
      fontWeightBold: 400,
      color: finalPalette.primary.bodyText,
      h1: {},
      h2: {
        fontWeight: 600,
        color: finalPalette.primary.bodyText,
      },
      h3: {},
      h4: {},
      h5: {
        color: finalPalette.primary.paperText,
      },
      h6: {
        color: finalPalette.primary.paperText,
      },
      body1: {
        color: finalPalette.primary.paperText,
        '& b': {
          color: finalPalette.primary.main,
          fontWeight: 'bold',
        },
      },
      body2: {
        color: finalPalette.primary.bodyText,
      },
      subtitle1: {
        fontWeight: 600,
        width: '100%',
        marginTop: 100,
        marginBottom: 50,
        fontSize: 24,
        color: finalPalette.primary.main,
      },
      subtitle2: {
        color: finalPalette.primary.bodyText,
      },
      helper: {
        primary: finalPalette.common.grey['100'],
        secondary: opacity(finalPalette.common.grey['100'], 0.54),
        light: '#DFE9E9',
      },
    },
    overrides: {
      MuiListItemIcon: {
        root: {
          color: finalPalette.primary.bodyText,
        },
      },
      MuiTypography: {
        root: {
          color: finalPalette.primary.bodyText,
        },
        colorTextSecondary: {
          color: finalPalette.primary.contrastText,
        },
        contained: {},
        outlined: {},
        text: {},
        sizeSmall: {},
        sizeLarge: {},
      },
      MuiFormLabel: {
        root: {
          color: finalPalette.primary.paperText,
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:select:-webkit-autofill, &:select:-webkit-autofill:hover,&:select:-webkit-autofill:focus,&:-internal-autofill-selected,&:-webkit-autofill, &:-internal-autofill-selected, &:focus:-webkit-autofill, &:hover:-webkit-autofill, &:active:-webkit-autofill':
            {
              webkitTextFillColor: finalPalette.primary.paperText + ' !important',
              textFillColor: finalPalette.primary.paperText + ' !important',
              color: finalPalette.primary.paperText + ' !important',
              backgroundColor: 'inherit !important', // Inherit the parent background color
              borderColor: 'inherit !important', // Inherit the parent border color
              boxShadow: 'inherit !important', // Inherit the parent box shadow
            },
        },
      },
      MuiInputBase: {
        root: {
          color: finalPalette.primary.paperText,
          '&::placeholder': {
            backgroundColor: 'inherit !important', // Inherit the parent background color
          },
          '&:select:-webkit-autofill, &:select:-webkit-autofill:hover,&:select:-webkit-autofill:focus,&:-internal-autofill-selected,&:-webkit-autofill, &:-internal-autofill-selected, &:focus:-webkit-autofill, &:hover:-webkit-autofill, &:active:-webkit-autofill':
            {
              webkitTextFillColor: finalPalette.primary.paperText + ' !important',
              textFillColor: finalPalette.primary.paperText + ' !important',
              color: finalPalette.primary.paperText + ' !important',
              backgroundColor: 'inherit !important', // Inherit the parent background color
              borderColor: 'inherit !important', // Inherit the parent border color
              boxShadow: 'inherit !important', // Inherit the parent box shadow
            },
        },
      },
      MuiBadge: {
        colorSecondary: {
          backgroundColor: finalPalette.primary.main + ' !important',
          color: finalPalette.common.white,
        },
      },
      MuiPaper: {
        root: {
          /*
          background:
            'radial-gradient(circle, ' +
            finalPalette.primary.main +
            ' 20%, ' +
            finalPalette.backgrounds.paper +
            ' 60%)',*/
          //backgroundColor: finalPalette.backgrounds.paper,
          //backgroundColor: opacity('#0F1418', 0.8) || 'rgba(255,255,255, 0.3)' || finalPalette.backgrounds.paper,
          backgroundColor: 'rgba(255,255,255, 0.3)' || finalPalette.backgrounds.paper,
          backdropFilter: 'blur(5px)',
          borderRadius: '0px',
          boxShadow: '0 0 4px 0 rgba(255, 255, 255, 0.5) !important',
          //boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
          color: finalPalette.primary.paperText,
          '& button.MuiButton-containedPrimary': {
            color: finalPalette.primary.paperText + ' !important',
          },
          '& button.MuiButton-containedSecondary': {
            color: finalPalette.primary.paperText + ' !important',
          },
          '& button.MuiButton-root.Mui-disabled': {
            color: finalPalette.primary.paperText + ' !important',
          },
          '& button.MuiIconButton-root': {
            color: finalPalette.primary.paperText + ' !important',
          },
          '& .MuiPaper-outlined': {
            color: finalPalette.primary.text,
            backgroundColor: finalPalette.primary.main,
          },
          '& .MuiButton-root': {
            color: finalPalette.primary.text,
          },
          '& h5': {
            color: finalPalette.primary.paperText,
          },
          '& .MuiPaper-root.MuiStepper-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'inherit',
            boxShadow: 'inherit !important',
          },
          '& .MuiPaper-root.MuiPaper-root': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: finalPalette.common.grey['500'],
            backgroundColor: 'transparent',
            backdropFilter: 'inherit',
            boxShadow: 'inherit !important',
          },
        },
      },
      MuiStepLabel: {
        root: {
          color: finalPalette.primary.text,
          '& .MuiStepLabel-label': {
            color: finalPalette.primary.text,
          },
          '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: finalPalette.primary.text,
          },
          '& .MuiStepLabel-label.MuiStepLabel-completed': {
            color: finalPalette.primary.text,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: finalPalette.primary.paperText,
        },
      },
      MuiButton: {
        root: {},
        containedPrimary: {
          //backgroundImage: 'linear-gradient(to right, #eb4e7c, #e78a51)',
          backgroundImage: 'linear-gradient(to right, #fd5c87, #fd6f5c)',
          color: finalPalette.common.white,
          padding: 6,
          transition: 'all 0.1s ease-in-out',
          '&:hover': {
            backgroundImage: 'linear-gradient(to right, #fd6f5c, #fd5c87)',
            transform: 'scale(1.01)',
            //            boxShadow: '0 0 10px 5px rgba(235, 78, 124, 0.5)',
          },
          '&.Mui-disabled': {
            opacity: 0.7,
          },
        },
        contained: {},
        outlined: {},
        text: {},
        sizeSmall: {},
        sizeLarge: {},
      },
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 641,
        md: 960,
        lg: 1080,
        xl: 1920,
      },
    },
  });
};
export default theme;
