import { useQuery } from 'react-query';
import {getBibleGPTModernCommentary} from "../api/getBibleGPTModernCommentary";

const useBibleGPTModernCommentary = (book,chapter) => {
  const result = useQuery('biblegpt_modern_commentary_'+book+'_'+chapter, () => getBibleGPTModernCommentary(book,chapter), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useBibleGPTModernCommentary;
