import React, {useContext, useMemo} from 'react'
import {Card, CardContent, CircularProgress, Typography} from "@material-ui/core";

import 'zingchart/es6';
import ZingChart from 'zingchart-react';
// EXPLICITLY IMPORT MODULE
import 'zingchart/modules/zingchart-depth.min.js';
import 'zingchart/modules-es6/zingchart-maps.min.js';
// import 'zingchart/modules-es6/zingchart-maps-usa.min.js';
// import 'zingchart/modules-es6/zingchart-maps-can.min.js';
// import 'zingchart/modules-es6/zingchart-maps-bra.min.js';
// import 'zingchart/modules-es6/zingchart-maps-ind.min.js';
import 'zingchart/modules-es6/zingchart-maps-world-countries.min.js';
import {ThemeContext} from "../../lib/contexts";
import {SearchContext} from "../../lib/contexts";
import zingchart from "zingchart";

export default function EventsByLocation() {
   const { darkMode } = useContext(ThemeContext);
   
   const {
      state: {bibleGPTLoading, bibleGPTData, matchSM}
   } = useContext(SearchContext);
   
   const { Where, Event_Coordinates } = bibleGPTData || {}
   
   const markers = useMemo(
      ()=>
         Event_Coordinates ? Event_Coordinates.map((event,index)=>{
            let label = event.Event;
            let lat = event.Lat;
            let long = event.Long;
            //console.log(lat,long)
            return {
               type: "circle", // shapeid is OPTIONAL but smart if you are targeting events to this shape
               id: 'events_'+index,
               x: long+"lon", // hook shape based on lon/lat
               y: lat+"lat",   // hook shape based on lon/lat
               map: 'world', // assigning to map name or id is necessary
               size: 5,
               backgroundColor: '#DB6768',
               cursor: "pointer",
               tooltip: {
                  "backgroundColor": "#555",
                  "borderColor": "#555",
                  "borderRadius": 4,
                  "color": "#fff",
                  "fontSize": 16,
                  "text": label
               }
            }
         }) : [],[Event_Coordinates]
   );
   
   const [minLon, minLat, maxLon, maxLat] = markers.reduce(
      (prev, curr) => {
         const { x, y } = curr;
         const [currMinLon, currMinLat, currMaxLon, currMaxLat] = prev;
         return [         Math.min(parseFloat(x), currMinLon),         Math.min(parseFloat(y), currMinLat),         Math.max(parseFloat(x), currMaxLon),         Math.max(parseFloat(y), currMaxLat),      ];
      },
      [Infinity, Infinity, -Infinity, -Infinity],
   );
   
   const zoomLevel = 4; // adjust to your liking
   const centerLat = -(minLat + maxLat) / 1.1;
   const centerLon = (minLon + maxLon) / 2;
   
   const config = {
      graphset: [
         {
            backgroundColor: 'transparent',
            shapes: [
               {
                  type: 'zingchart.maps',
                  options: {
                     id:"world",
                     name: 'world.countries',
                     zoom: zoomLevel,
                     offsetX: centerLat,
                     offsetY: centerLon,
                     style: { //style all countries
                        backgroundColor: !darkMode ? '#556E7A' : '#BDBDBD',
                        label: {
                           visible: false
                        },
                        hoverState: {
                           alpha: .32,
                        },
                        itemsdisabled: { //include specific shape regions with unique styles
                           CAN: {
                              cursor: 'pointer',
                              label: {
                                 visible: true,
                                 fontColor: '#ffffff'
                              },
                              tooltip: {
                                 text: 'Click on Canada to drilldown into provinces'
                              }
                           },
                           USA: {
                              cursor: 'pointer',
                              label: {
                                 visible: true,
                                 fontColor: '#ffffff'
                              },
                              tooltip: {
                                 text: 'Click on USA to drilldown into states'
                              }
                           },
                           BRA: {
                              cursor: 'pointer',
                              label: {
                                 visible: true,
                                 fontColor: '#ffffff'
                              },
                              tooltip: {
                                 text: 'Click on Brazil to drilldown into states'
                              }
                           },
                           IND: {
                              cursor: 'pointer',
                              label: {
                                 visible: true,
                                 fontColor: '#ffffff'
                              },
                              tooltip: {
                                 text: 'Click on India to drilldown into states'
                              }
                           }
                           
                        }
                     },
                     ignore: ['ATA']
                  }
               },
               ...markers
            ]
         }
      ]
   };
   const drilldownConfig = {
      shapes:[
         {
            type:'zingchart.maps',
            options:{
               name:'',
            }
         },
         {
            x:100,
            y:20,
            type:'rectangle',
            height: 25,
            width: 155,
            backgroundColor:'#C4C4C4',
            padding:5,
            cursor:'hand',
            id: 'back_button',
            label: {
               text: 'Back To World Countries'
            },
            hoverState: {
               borderWidth: 1,
               borderColor: '#000'
            }
         }
      ]
   };
   
   zingchart.bind('myChart', 'shape_click', function(e) {
      var newMapId = String(e.shapeid).toLowerCase();
      
      // if shape is our back button and not the map
      if (newMapId == 'back_button') {
         //console.log('here')
         // since we are using setdata, reload will reload the original chartJSON
         zingchart.exec('myChart', 'reload');
         return;
      }
      
      if (hasDrilldownData(newMapId)) {
         drilldownConfig.shapes[0].options.name =  newMapId;
         zingchart.exec('myChart', 'setdata', {
            data: drilldownConfig
         });
      }
   });
   
   zingchart.load=function() {
      {/*
    config.shapes.push({
      id : 'airport',
      type : 'circle',
      size : 5,
      'background-color' : 'red',
      y : 38.2527 + 'lat',
      x : -85.7585 + 'lon',
      map : 'usa',
      'hover-state' : {
        size : 10 + 4,
        'background-color' : 'none',
        'border-width' : 2,
        'border-color' : 'red'
      },
      tooltip : {
        text : '<span style="font-size:15px">' + 'd' + '</span><br>'
      }
    });
    */}
   };


// used in the shape_click event to determine if we should drilldown
   function hasDrilldownData(newMapId) {
      var drillDownCountries = ['world.countries', 'usa', 'bra', 'ind'];
      for (var i = 0; i < drillDownCountries.length; i++) {
         if (newMapId === drillDownCountries[i])
            return true;
      }
      return false;
   }
   
   return (
      <Card style={{marginTop:20, height: matchSM ? 300 : 400}}>
         <CardContent>
            <Typography variant="h5">
               Where
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'50px auto auto'}}/> :
               <>
               {Where}
               <br/>
               <ZingChart data={config} style={{height: matchSM ? 200 : 250}}/>
            </>}
         </CardContent>
      </Card>
  );
}
