import React, {useContext, useEffect, useRef} from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import TOP_WORDS from '../../assets/en_kjv_top_words.json';
import {Card, CardContent, Typography} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";

const WordCloud = () => {
   const svgRef = useRef(null);
   
   const {
      state: { matchSM},
      actions: { setQuery}
   } = useContext(SearchContext);
   
   useEffect(() => {
      const width = matchSM ? '100%' : '500';
      const height = matchSM ? '100%' : 250;
      
      const svg = d3.select(svgRef.current)
         .attr('width', width)
         .attr('height', height)
         .append('g')
         .attr('transform', `translate(${width / 2}, ${height / 2})`);
      
      const layout = cloud()
         .size([width, height])
         .words(TOP_WORDS.top_words.map(d => ({
            text: d.word,
            size: 10 + d.count / 100,
            url: `/?q=${d.word}`
         })))
         .padding(5)
         .rotate(() => ~~(Math.random() * 2) * 90)
         .font('Impact')
         .fontSize(d => d.size)
         .on('end', draw);
      
      layout.start();
      
      function draw(words) {
         svg.selectAll('text')
            .data(words)
            .join('a')
            .attr('xlink:href', d => d.url)
            .append('text')
            .style('font-size', d => `${d.size}px`)
            .style('font-family', 'Impact')
            //.style('fill', (d, i) => d3.schemeCategory10[i % 10])
            .style('fill', '#fff')
            .attr('text-anchor', 'middle')
            .attr('transform', d => `translate(${d.x}, ${d.y})rotate(${d.rotate})`)
            .text(d => d.text);
      }
   }, []);
   
   return (
      <>
         <Card style={{marginTop:20, height: matchSM ? 210 : 260}}>
            <CardContent>
         <Typography variant="h5">
            Top Words
         </Typography>
         <svg ref={svgRef} />
            </CardContent>
         </Card>
      </>
   );
};

export default WordCloud;
