import { useQuery } from 'react-query';
import { getBibleGPT } from '../api/getBibleGPT';

const useBibleGPT = (book,chapter) => {
  const result = useQuery('biblegpt_'+book+'_'+chapter, () => getBibleGPT(book,chapter), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default useBibleGPT;
