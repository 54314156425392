import {bookAbbreviations, bookNames} from "./constants";
import TOP_WORDS from '../../assets/en_kjv_top_words.json';
import BOOK from '../../assets/en_kjv_bible.json';
//import TIMELINE from '../../assets/en_kjv_timeline_events.json.json';

export const prettyNumber = (number, stripeMode) => {
   return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
   }).format(number);
};
export const prettyDateTime = (time) => {
  let date = new Date(time);
  return (
    date.toLocaleDateString('en', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    }) +
    ' at ' +
    date.toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '')
  );
};
export const prettyDate = (time) => new Date(time).toLocaleDateString('en', {});

export const prettyDateLong = (time) => new Date(time).toLocaleDateString('en', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

export const prettyTime = (time) => new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

export const prettyHour = (time) =>
  new Date(time).toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' }).replace(/^0(?:0:0?)?/, '');

export const prettyPhone = (number) =>
  number.substr(2).replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function (_, p1, p2, p3, p4) {
    let output = '';
    if (p1) output = `${p1}`;
    if (p2) output += `${p2}-`;
    if (p3) output += `${p3}-`;
    if (p4) output += `${p4}`;
    return output;
  });

export const toCapitalize = (sentence) => sentence.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

export const toCapitalizeFromUnderScore = (name) =>  name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
export const toHashtag = (str) => {
   return '#' + str.replace(/[^\w\s]/gi, '').replace(/\s/g, '');
}

export const groupBy = (list, key) => {
  return list.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const keyBy = (array, key) => (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export const keyByCollection = (collection, key) => {
  const c = collection || {};
  return c.isArray() ? keyBy(c, key) : Object.values(keyBy(c, key));
}
export const compactArray = (array) => array.filter(Boolean)

export const getCookie = (n) => {
  let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : '';
}
export const setCookie = (name,value,days = 365) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export const eraseCookie = (name) => {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const roundHalf = (n) => {
  return (Math.round(n*2)/2).toFixed(1);
}

export const cmToInFt = (cm, inches = Math.round(cm / 2.54)) => {
  return Math.floor(inches / 12)+"'"+inches % 12+'"'
}

export const kgToLbs = (kg) => {
  return Math.floor(kg * 2.20462262185)
}

export const truncate = (string) => {
  return string
}
export const getUrlParam = (name) => {
   const urlParams = new URLSearchParams(window.location.search)
   return urlParams.get(name)
}

export const isMobile = () => {
   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
   
   // Check if the user agent is an Android device
   if (/android/i.test(userAgent)) {
      return true;
   }
   
   // Check if the user agent is an iOS device
   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
   }
   
   // Otherwise, the user is not on a mobile device
   return false;
}

export const removeFromBeginning = (str) => {
   return str.replace(/^.+?(?=\s|$)/, '');
}

//BibleGPT Specific


export const getNameFromAbbreviation = (abbreviation) => {
   const index = bookAbbreviations.indexOf(abbreviation);
   if (index === -1) {
      throw new Error('Invalid abbreviation');
   }
   return bookNames[index];
}
// Usage example
//console.log(getNameFromAbbreviation('gn')); // Output: Genesis

export const filterVersesByKeyword = (keyword) => {
   const filteredVerses = [];
   Boolean(keyword) && BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach((verse, index) => {
            if (verse && verse.toLowerCase().includes(keyword.toLowerCase())) {
               filteredVerses.push({
                  book: getNameFromAbbreviation(book.abbrev),
                  chapter: index + 1,
                  verse
               });
            }
         });
      });
   });
   return filteredVerses;
};

export const getUniqueWordCountInBook = () => {
   const bookWordCount = {};
   
   TOP_WORDS.top_words.forEach(topWord => {
      bookWordCount[topWord.word] = 0;
      BOOK.forEach(book => {
         book.chapters.forEach(chapter => {
            chapter.forEach(verse => {
               if (verse.toLowerCase().includes(topWord.word.toLowerCase())) {
                  bookWordCount[topWord.word]++;
               }
            });
         });
      });
   });
   
   return bookWordCount;
};




export const getWordFrequencyInEachBook = (TERM) => {
   const wordData = TOP_WORDS.top_words.find(w => w.word === TERM);
   if (!wordData) {
      return [];
   }
   const bookWordFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               if (!bookWordFrequency[book.abbrev]) {
                  bookWordFrequency[book.abbrev] = 0;
               }
               bookWordFrequency[book.abbrev] += 1;
            }
         });
      });
   });
   
   return bookWordFrequency;
};

export const getBigramFrequencyInEachBook = (TERM) => {
   const bigramFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               if (!bigramFrequency[book.abbrev]) {
                  bigramFrequency[book.abbrev] = 0;
               }
               bigramFrequency[book.abbrev] += 1;
            }
         });
      });
   });
   
   return bigramFrequency;
};

export const getTrigramFrequencyInEachBook = (TERM) => {
   const trigramFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               if (!trigramFrequency[book.abbrev]) {
                  trigramFrequency[book.abbrev] = 0;
               }
               trigramFrequency[book.abbrev] += 1;
            }
         });
      });
   });
   
   return trigramFrequency;
};
export const getVerseFrequencyInEachChapter = (TERM) => {
   const verseFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               const chapterNum = chapter.indexOf(verse) + 1;
               if (!verseFrequency[chapterNum]) {
                  verseFrequency[chapterNum] = 0;
               }
               verseFrequency[chapterNum] += 1;
            }
         });
      });
   });
   
   return verseFrequency;
};
export const getVerseFrequencyInEachBook = (TERM) => {
   const verseFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               if (!verseFrequency[book.abbrev]) {
                  verseFrequency[book.abbrev] = 0;
               }
               verseFrequency[book.abbrev] += 1;
            }
         });
      });
   });
   
   return verseFrequency;
};

export const getVerseFrequencyInEachChapterAndBook = (TERM) => {
   const verseFrequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               const chapterNum = chapter.indexOf(verse) + 1;
               if (!verseFrequency[book.abbrev + '-' + chapterNum]) {
                  verseFrequency[book.abbrev + '-' + chapterNum] = 0;
               }
               verseFrequency[book.abbrev + '-' + chapterNum] += 1;
            }
         });
      });
   });
   
   return verseFrequency;
};

export const getVerseWordCountInEachChapterAndBook = (TERM) => {
   const wordCount = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               const chapterNum = chapter.indexOf(verse) + 1;
               if (!wordCount[book.abbrev + '-' + chapterNum]) {
                  wordCount[book.abbrev + '-' + chapterNum] = 0;
               }
               wordCount[book.abbrev + '-' + chapterNum] += verse.split(' ').length;
            }
         });
      });
   });
   
   return wordCount;
};

export const getVerseWordCountInEachBook = (TERM) => {
   const wordCount = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            const lowerCaseVerse = verse.toLowerCase();
            if (lowerCaseVerse.includes(TERM.toLowerCase())) {
               if (!wordCount[book.abbrev]) {
                  wordCount[book.abbrev] = 0;
               }
               wordCount[book.abbrev] += verse.split(' ').length;
            }
         });
      });
   });
   
   return wordCount;
};
export const countVersesPerChapter = (TERM) => {
   const count = {};
   BOOK.forEach(book => {
      book.chapters.forEach((chapter, chapterIndex) => {
         count[`${book.abbrev} ${chapterIndex + 1}`] = 0;
         chapter.forEach(verse => {
            if (verse.toLowerCase().includes(TERM.toLowerCase())) {
               count[`${book.abbrev} ${chapterIndex + 1}`] += 1;
            }
         });
      });
   });
   return count;
}
export const getWordCountByBook = (TERM) => {
   const count = {};
   BOOK.forEach(book => {
      count[book.abbrev] = 0;
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            if (verse.toLowerCase().includes(TERM.toLowerCase())) {
               count[book.abbrev] += 1;
            }
         });
      });
   });
   return count;
};

export const countWordsInVerses = (TERM) => {
   let count = 0;
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            if (verse.toLowerCase().includes(TERM.toLowerCase())) {
               count += 1;
            }
         });
      });
   });
   return count;
};

export const getAvgVerseLengthByBook = () => {
   const avgLengths = {};
   BOOK.forEach(book => {
      let totalLength = 0;
      let verseCount = 0;
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            totalLength += verse.length;
            verseCount += 1;
         });
      });
      avgLengths[book.abbrev] = totalLength / verseCount;
   });
   return avgLengths;
};

export const getAvgWordLengthInVerses = () => {
   let totalLength = 0;
   let wordCount = 0;
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            totalLength += verse.length;
            wordCount += verse.split(' ').length;
         });
      });
   });
   return totalLength / wordCount;
};

export const getWordFrequencyInVerses = () => {
   const frequency = {};
   BOOK.forEach(book => {
      book.chapters.forEach(chapter => {
         chapter.forEach(verse => {
            verse.split(' ').forEach(word => {
               if (!frequency[word]) {
                  frequency[word] = 0;
               }
               frequency[word] += 1;
            });
         });
      });
   });
   return frequency;
};
export const getBooksWithMostVersesForTerm = (TERM) => {
   const filteredVerses = filterVersesByKeyword(TERM);
   const bookCounts = {};
   filteredVerses.forEach(verse => {
      if (!bookCounts[verse.book]) {
         bookCounts[verse.book] = 0;
      }
      bookCounts[verse.book] += 1;
   });
   
   return Object.entries(bookCounts)
      .sort((a, b) => b[1] - a[1])
      .map(([book, count]) => ({ book, count }));
};
export const getChaptersWithMostVersesForTerm = (TERM) => {
   const filteredVerses = filterVersesByKeyword(TERM);
   const chapterCounts = {};
   filteredVerses.forEach(verse => {
      if (!chapterCounts[verse.book + verse.chapter]) {
         chapterCounts[verse.book + verse.chapter] = 0;
      }
      chapterCounts[verse.book + verse.chapter] += 1;
   });
   
   return Object.entries(chapterCounts)
      .sort((a, b) => b[1] - a[1])
      .map(([chapter, count]) => ({ chapter: chapter.split(' ')[1], count }));
};
