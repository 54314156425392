export const ENVIRONMENT = [
  ['production', window.location.origin.includes('capacitor://localhost')],
  ['development', !window.location.origin.includes('capacitor://localhost') && !window.location.origin.includes('biblegpt.ai') && !window.location.origin.includes('biblegpt.pages.dev')],
  ['staging', window.location.origin.includes('beta.biblegpt.ai')],
  ['production', !window.location.origin.includes('beta.biblegpt.ai') && ( window.location.origin.includes('biblegpt.ai') || window.location.origin.includes('biblegpt.pages.dev'))]
].find(([name, isActive]) => isActive)[0]

export const CLIENT_HOST = {
   development: `http://localhost:3000`,
   staging: `https://beta.biblegpt.ai`,
   production: `https://biblegpt.ai`
}[ENVIRONMENT]

export const API_HOST = {
  development: 'http://localhost:5005/v1',
  staging: 'https://api.formulatelabs.com/v1',
  production: 'https://api.formulatelabs.com/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: null,
  production: null
}[ENVIRONMENT]


export const bookAbbreviations = [
   'gn', 'ex', 'lv', 'nm', 'dt', 'js', 'jud', 'rt', '1sm', '2sm', '1kgs', '2kgs',
   '1ch', '2ch', 'ezr', 'ne', 'et', 'job', 'ps', 'prv', 'ec', 'so', 'is', 'jr',
   'lm', 'ez', 'dn', 'ho', 'jl', 'am', 'ob', 'jn', 'mi', 'na', 'hk', 'zp', 'hg',
   'zc', 'ml', 'mt', 'mk', 'lk', 'jo', 'act', 'rm', '1co', '2co', 'gl', 'eph',
   'ph', 'cl', '1ts', '2ts', '1tm', '2tm', 'tt', 'phm', 'hb', 'jm', '1pe', '2pe',
   '1jo', '2jo', '3jo', 'jd', 're'
];

export const bookNames = [
   'Genesis', 'Exodus', 'Leviticus', 'Numbers', 'Deuteronomy', 'Joshua', 'Judges', 'Ruth', '1 Samuel', '2 Samuel', '1 Kings', '2 Kings',
   '1 Chronicles', '2 Chronicles', 'Ezra', 'Nehemiah', 'Esther', 'Job', 'Psalms', 'Proverbs', 'Ecclesiastes', 'Song of Solomon', 'Isaiah', 'Jeremiah',
   'Lamentations', 'Ezekiel', 'Daniel', 'Hosea', 'Joel', 'Amos', 'Obadiah', 'Jonah', 'Micah', 'Nahum', 'Habakkuk', 'Zephaniah', 'Haggai',
   'Zechariah', 'Malachi', 'Matthew', 'Mark', 'Luke', 'John', 'Acts', 'Romans', '1 Corinthians', '2 Corinthians', 'Galatians', 'Ephesians',
   'Philippians', 'Colossians', '1 Thessalonians', '2 Thessalonians', '1 Timothy', '2 Timothy', 'Titus', 'Philemon', 'Hebrews', 'James', '1 Peter', '2 Peter',
   '1 John', '2 John', '3 John', 'Jude', 'Revelation'
];
